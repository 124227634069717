import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { Site, User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { FuseAlertType } from '@fuse/components/alert';
import { SocketioService } from 'app/shared/services/socketio.service';
import { RoomService } from 'app/modules/admin/rooms/room/room.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass, NgFor } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'site',
    templateUrl: './site.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'site',
    standalone     : true,
    imports        : [MatButtonModule, MatIconModule, MatTooltipModule, NgFor, NgClass, MatMenuModule],
})
export class SiteComponent implements OnInit, OnDestroy {
    user: User;
    availableSites: Site[];
    siteName: string;
    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    showAlert: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _roomService: RoomService,
        private _router: Router,
        private _userService: UserService,
        private _socketioService: SocketioService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user sites changes
        this._userService.sites$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((sites: Site[]) => {
                this.availableSites = sites;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this.siteName = user?.site.name;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Update the user site
     *
     * @param site
     */
    updateUserSite(site: number): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        if (site !== this.user.site.id) {
            this._userService.changeSite(site)
                .pipe(
                    finalize(() => {
                        // Show the alert
                        this.showAlert = true;
                    })
                )
                .subscribe(
                    (response) => {
                        // Set the alert
                        if (response.errors) {
                            this.alert = {
                                type: 'error',
                                message: response.errors[0].message
                            };
                        } else {
                            // Remove the access token from the local storage to force sign in
                            const res: any = response['data']['changeSite'];

                            // Update user with site info
                            const newUser = this.user;
                            newUser.site = res.site;
                            newUser.accessToken = res.accessToken;
                            newUser.expiresIn = res.expiresIn;
                            this._userService.user = newUser;

                            // Update local storage
                            localStorage.setItem('site_name', res.site.name);
                            localStorage.setItem('time_zone', res.site.timezone);
                            localStorage.setItem('accessToken', res.accessToken);

                            // Close Socket Connection
                            this._socketioService.disconnectSocketConnection();

                            // Setup Socket Connection
                            this._socketioService.setupSocketConnection();

                            // Load rooms
                            this._roomService.rooms = null;
                            this._roomService.getRooms().subscribe();

                            // refresh page
                            let currentUrl = this._router.url;

                            if (currentUrl.includes('/room/') || currentUrl.includes('pages/error/')) {
                                const homePage = localStorage.getItem('home_page_url');
                                const redirectURL = homePage === "" ? '/dashboards/site-overview' : homePage;
                                this._router.navigate([redirectURL]);
                            } else {
                                this._router.navigateByUrl('/pages/page-loading').then(() => {
                                    this._router.navigateByUrl(currentUrl);
                                })
                            }

                            this.alert = {
                                type: 'success',
                                message: 'Your site has been changed.'
                            };
                        }
                    },
                    (response) => {

                        // Set the alert
                        this.alert = {
                            type: 'error',
                            message: 'Something went wrong, please try again.'
                        };
                    }
                );

        }
    }
}
