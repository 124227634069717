import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help-center/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';

export const mockApiServices = [
    AuthMockApi,
    HelpCenterMockApi,
    IconsMockApi,
    NavigationMockApi,
    SearchMockApi,
    ShortcutsMockApi,
    UserMockApi
];
