/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 200,
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 207,
                title: 'Site Overview',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/site-overview'
            },
            // {
            //     id: 201,
            //     title: 'Room Overview',
            //     type: 'basic',
            //     icon: 'mat_outline:room_preferences',
            //     link: '/dashboards/room-overview'
            // },
            // {
            //     id: 204,
            //     title: 'Current Issues',
            //     type: 'basic',
            //     icon: 'heroicons_outline:exclamation',
            //     link: '/dashboards/issues'
            // },
            // {
            //     id: 205,
            //     title: 'Service Alerts',
            //     type: 'basic',
            //     icon: 'room_service',
            //     link: '/dashboards/service-alert'
            // },
            // {
            //     id: 202,
            //     title: 'HVAC State',
            //     type: 'basic',
            //     icon: 'mat_outline:hvac',
            //     link: '/dashboards/hvac'
            // },
            // {
            //     id: 203,
            //     title: 'Temperature',
            //     type: 'basic',
            //     icon: 'feather:thermometer',
            //     link: '/dashboards/temperature'
            // },
            // {
            //     id: 206,
            //     title: 'Privacy Service',
            //     type: 'basic',
            //     icon: 'mat_outline:do_not_disturb',
            //     link: '/dashboards/privacy-service'
            // }
        ]
    },
    // {
    //     id: 300,
    //     title: 'Devices',
    //     subtitle: 'View device lists',
    //     type: 'group',
    //     icon: 'mat_outline:devices',
    //     children: [
    //         {
    //             id: 301,
    //             title: 'Offline',
    //             type: 'basic',
    //             icon: 'heroicons_outline:status-offline',
    //             link: '/devices/offline'
    //         },
    //         {
    //             id: 302,
    //             title: 'Unidentified',
    //             type: 'basic',
    //             icon: 'mat_outline:link_off',
    //             link: '/devices/unidentified'
    //         }
    //     ]
    // },
    // {
    //     id: 700,
    //     title: 'Administration',
    //     subtitle: 'Manage User accounts',
    //     type: 'group',
    //     icon: 'mat_outline:security',
    //     children: [
    //         {
    //             id: 701,
    //             title: 'Users',
    //             type: 'basic',
    //             icon: 'heroicons_outline:user',
    //             link: '/admin/users'
    //         },
    //         {
    //             id: 702,
    //             title: 'Sockets',
    //             type: 'basic',
    //             icon: 'mat_outline:bolt',
    //             link: '/admin/sockets'
    //         },
    //         {
    //             id: 703,
    //             title: 'Room Tags',
    //             type: 'basic',
    //             icon: 'heroicons_outline:tag',
    //             link: '/admin/room-tags'
    //         }
    //     ]
    // },
    {
        id: 1,
        type: 'divider'
    },
    {
        id: 800,
        title: 'Documentation',
        subtitle: 'Everything you need to know about HotDash',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 801,
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:speakerphone',
                link: '/docs/changelog',
                badge: {
                    title: '3.0.3',
                    classes: 'px-2 bg-yellow-300 text-black rounded-full'
                }
            },
            {
                id: 802,
                title: 'Guides',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/docs/guides'
            }
        ]
    },
    {
        id: 2,
        type: 'divider'
    },
    {
        id: 900,
        title: 'Help Centre',
        type: 'group',
        icon: 'heroicons_outline:question-mark-circle',
        children: [
            {
                id: 901,
                title: 'Support',
                type: 'basic',
                icon: 'heroicons_outline:support',
                link: '/help-center/support'
            }
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 200,
        title: 'Dashboards',
        tooltip: 'Dashboards',
        type: 'aside',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 300,
        title: 'Devices',
        tooltip: 'Devices',
        type: 'aside',
        icon: 'mat_outline:devices',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 700,
        title: 'Administration',
        tooltip: 'Administration',
        type: 'aside',
        icon: 'mat_outline:security',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 750,
        title: 'Drivers',
        tooltip: 'Drivers',
        type: 'aside',
        icon: 'mat_solid:drive_eta',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 800,
        title: 'Documentation',
        tooltip: 'Documentation',
        type: 'aside',
        icon: 'heroicons_outline:support',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 900,
        title: 'Help Centre',
        tooltip: 'Help Centre',
        type: 'aside',
        icon: 'heroicons_outline:question-mark-circle',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 200,
        title: 'DASHBOARDS',
        type: 'group',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 300,
        title: 'DEVICES',
        type: 'group',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 0,
        title: 'OTHERS',
        type: 'group',
    },
    {
        id: 700,
        title: 'Administration',
        type: 'aside',
        icon: 'mat_outline:security',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 750,
        title: 'Drivers',
        type: 'aside',
        icon: 'mat_solid:drive_eta',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 800,
        title: 'Documentation',
        type: 'aside',
        icon: 'heroicons_outline:support',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 900,
        title: 'Help Centre',
        type: 'aside',
        icon: 'heroicons_outline:question-mark-circle',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 200,
        title: 'Dashboards',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 300,
        title: 'Devices',
        type: 'group',
        icon: 'mat_outline:devices',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 700,
        title: 'Administration',
        type: 'group',
        icon: 'mat_outline:security',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 750,
        title: 'Drivers',
        type: 'group',
        icon: 'mat_solid:drive_eta',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 800,
        title: 'Documentation',
        type: 'group',
        icon: 'heroicons_outline:support',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 900,
        title: 'Help Centre',
        type: 'group',
        icon: 'heroicons_outline:question-mark-circle',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
