<div class="w-full max-w-3xl">

    <!-- Form -->
    <form [formGroup]="securityForm" #securityNgForm="ngForm">

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Change your password</div>
            <div class="text-secondary">You can only change your password twice within 24 hours!</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <!-- Current password -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Current password</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                    <input [formControlName]="'oldPassword'" type="password" matInput required>
                </mat-form-field>
            </div>
            <!-- New password -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>New password</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                    <input [formControlName]="'password'" type="password" matInput required minLength="8">
                    <mat-error *ngIf="securityForm.get('password').hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-error *ngIf="!securityForm.get('password').hasError('required') &&
                        securityForm.get('password').hasError('minlength')">
                        Password must be at least 8 characters
                    </mat-error>
                </mat-form-field>
                <div class="mt-1 text-md text-hint">Minimum 8 characters.</div>
            </div>
            <!-- New password Confirm -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Confirm New password</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
                    <input formControlName="confirmPassword" type="password" matInput required>
                    <mat-error *ngIf="securityForm.get('confirmPassword').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error
                        *ngIf="!securityForm.get('confirmPassword').hasError('required') &&
                                                   securityForm.get('confirmPassword').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Divider -->
        <div class="my-10 border-t"></div>

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Security preferences</div>
            <div class="text-secondary">Keep your account more secure with following preferences.</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <!-- Ask to change password -->
            <div class="sm:col-span-4 flex items-center justify-between">
                <div class="flex-auto cursor-pointer" (click)="askPasswordChangeToggle.toggle()">
                    <div class="leading-6 font-medium">Ask to change password every 6 months</div>
                    <div class="text-md text-secondary">
                        A simple but an effective way to be protected against data leaks and password theft.
                    </div>
                </div>
                <mat-slide-toggle class="ml-4" [color]="'primary'" [formControlName]="'askPasswordChange'"
                    #askPasswordChangeToggle>
                </mat-slide-toggle>
            </div>
        </div>

        <!-- Divider -->
        <div class="my-10 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
            <div class="flex items-center mr-4" *ngIf="flashMessage">
                <ng-container *ngIf="flashMessage === 'success'">
                    <mat-icon class="text-green-500" [svgIcon]="'heroicons_outline:check'"></mat-icon>
                    <span class="ml-2">Password updated</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon class="text-red-500" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    <span class="ml-2">An error occurred, try again!</span>
                </ng-container>
            </div>
            <button mat-stroked-button type="button" (click)="cancel()">
                Cancel
            </button>
            <button class="ml-4" mat-flat-button type="button" [color]="'primary'" [disabled]="securityForm.invalid"
                (click)="changePassword()">
                Save
            </button>
        </div>
    </form>

</div>