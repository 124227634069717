import { NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'development',
    templateUrl: './development.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'development',
    standalone     : true,
    imports        : [NgIf],
})
export class DevelopmentComponent implements OnInit {
    isProduction: boolean;

    /**
     * Constructor
     */
    constructor() { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the environment
        this.isProduction = environment.production;
    }
}
