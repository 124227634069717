import { TextFieldModule } from '@angular/cdk/text-field';
import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseNavigationService } from '@fuse/components/navigation';
import { AuthService } from 'app/core/auth/auth.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'settings-account',
    templateUrl: './account.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, MatFormFieldModule, MatIconModule, MatInputModule, TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule]
})
export class SettingsAccountComponent implements OnInit {
    accountForm: UntypedFormGroup;
    company: string;
    flashMessage: 'success' | 'error' | null = null;
    menuItems: any;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _fuseNavigationService: FuseNavigationService,
        private _navigationService: NavigationService,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
                this.company = user.site.name;
            });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                const tmp = this._fuseNavigationService.getFlatNavigation(navigation.default);
                this.menuItems = tmp.filter((item) => {
                    return item.type.toLowerCase().includes('basic') && (item.link.startsWith('/dashboards') || item.link.startsWith('/devices'));
                });
            });

        // Create the form
        this.accountForm = this._formBuilder.group({
            homePage: [this.user.homePage, Validators.required],
            refreshRate: [this.user.refreshRate, [Validators.required, rateValidator]],
            stayLoggedIn: [this.user.stayLoggedIn, Validators.required],
            tempScale: [this.user.tempScale, Validators.required]
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {

            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 4000);
    }

    /**
     * Update user account
     */
    updateAccount(): void {
        // Get the account object
        let input = this.accountForm.getRawValue();

        // Update the account on the server
        this._userService.updateAccount(input)
            .subscribe(
                (response) => {
                    if (response.errors) {

                        // Show error
                        this.showFlashMessage('error');

                    } else {

                        // Return if user is not available
                        if (!this.user) {
                            this.showFlashMessage('error');
                            return;
                        }

                        // Check if stay logged in changed
                        if (this.user.stayLoggedIn !== input.stayLoggedIn) {
                            this._authService.resetRefresh(input.stayLoggedIn);
                        }

                        // Update local storage
                        localStorage.setItem('home_page', input.homePage);
                        localStorage.setItem('temp_scale', input.tempScale);
                        localStorage.setItem('refresh_rate', input.refreshRate);
                        localStorage.setItem('stay_logged_in', input.stayLoggedIn);

                        // Update user with new data
                        this._userService.user = ({
                            ...this.user,
                            tempScale: input.tempScale,
                            refreshRate: input.refreshRate,
                            stayLoggedIn: input.stayLoggedIn,
                            homePage: input.homePage
                        });

                        // }).subscribe();

                        // Show a success message
                        this.showFlashMessage('success');
                    }
                },
                (response) => {

                    // Show error
                    this.showFlashMessage('error');
                }
            );
    }
}


/**
 * Refresh Rate validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const rateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const rate = control.parent.get('refreshRate');

    if (!rate) {
        return null;
    }

    if (rate.value === '') {
        return null;
    }

    if (rate.value === 0 || rate.value >= 5) {
        return null;
    }

    return { rateError: true };
};
