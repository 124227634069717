import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { Site, User, UserPermission } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _fuseConfigService = inject(FuseConfigService);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _sites: BehaviorSubject<Site[] | null> = new BehaviorSubject(null);
    private _baseURL = environment.baseUrl;
    private _permissions: BehaviorSubject<UserPermission | null> = new BehaviorSubject(null);
    private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for sites
     */
    get sites$(): Observable<Site[]> {
        return this._sites.asObservable();
    }

    get sites(): Site[] {
        return this._sites.getValue();
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
        if (value) {
            this._permissions.next(value.permissions);
        } else {
            this._permissions.next(null);
        }
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user permissions
     */
    getPermissions(devTypeId: number)  {
        const permissions = this._permissions.getValue();

        if (permissions) {
            const deviceAccess = devTypeId === 0 ? false : permissions.control.includes(devTypeId);
            return {
                canDelete: permissions.specialActions.canDelete === 1 ? true : false,
                canDisconnect: permissions.specialActions.canDisconnect === 1 ? true : false,
                canExecute: deviceAccess ? (permissions.specialActions.canExecute === 1 ? true : false) : false,
                canExecuteMultiple: deviceAccess ? (permissions.specialActions.canExecuteMultiple === 1 ? true : false) : false,
                canHide: permissions.specialActions.canHide === 1 ? true : false,
                canIdentify: permissions.specialActions.canIdentify === 1 ? true : false,
                canProperties: deviceAccess ? permissions.specialActions.canProperties : 0
            }
        }

        return {
            canDelete: false,
            canDisconnect: false,
            canExecute: false,
            canExecuteMultiple: false,
            canHide: false,
            canIdentify: false,
            canProperties: 0
        }
    }

    /**
     * Get the current logged in user sites
     */
    getSites(): Observable<Site[]> {
        const body = `{ "query": "query { sitesAvailable {id name timezone location { city state country } licenseExpire }}" }`;
        return this._httpClient.post<Site[]>(this._baseURL + '/graphql', body, this._httpOptions).pipe(
            tap((sites) => {
                this._sites.next(sites['data']['sitesAvailable']);
            })
        );
    }

    changeSite(site: number) {
        const body = `{ "query": "mutation { changeSite ( siteId: ${site} ) { firstname lastname email tempScale homePage accessToken expiresIn site { id name timezone logo licenseExpire location { line1 line2 city state country } } shortcuts themeOptions }}" }`;
        return this._httpClient.post<any>(this._baseURL + '/graphql', body, this._httpOptions);
    }

    setTheme(): Observable<any> {
        let config = null;

        try {
            const opt = localStorage.getItem('theme_options');
            if (opt) {
                config = JSON.parse(opt);
            }

            if (config?.theme) {
                this._fuseConfigService.config = config;
            }

        } catch (e) {
            console.log('USER SERVICE setTheme : Theme error');
        }

        return config;
    }

    // /**
    //  * Update the user
    //  *
    //  * @param user
    //  */
    // update(user: User): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/user', { user }).pipe(
    //         map((response) => {
    //             this._user.next(response);
    //         })
    //     );
    // }

    /**
     * Update the user account
     *
     * @param input
     */
    updateAccount(input: any): Observable<any> {
        const query = 'mutation updateUser($input: updateUser) { updateUser(input: $input) { id }}';
        const body = JSON.stringify({
            query,
            variables: { input }
        })
        return this._httpClient.post(this._baseURL + '/graphql', body, this._httpOptions);
    }
}
