import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'elapsedTime' })

export class ElapsedTimePipe implements PipeTransform {

    transform(value: any, fmt: number) {
        if (value) {

            // fmt 1 : input = date, calculate elapsed from current time | <30sec = just now
            // fmt 2 : input = date, calculate elapsed from current time | abbrev | <30sec = just now
            // fmt 3 : input = elapsed seconds | abbrev | <30sec = just now
            // fmt 4 : input = elapsed seconds | abbrev
            // fmt 5 : input = date, calculate elapsed from current time | abbrev
            // fmt 10: input = date, calculate elapsed TO current time | <30sec = EXPIRED

            if (fmt === 1 || fmt === 2 || fmt === 3 || fmt === 4 || fmt === 5 || fmt === 10) {

                let seconds = 0;

                if (fmt === 3 || fmt === 4) {
                    seconds = value;
                } else if (fmt === 10) {
                    seconds = Math.floor((+new Date(value) - +new Date()) / 1000);
                } else {
                    seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
                }

                if (seconds < 30 && fmt !== 4 && fmt !== 5 && fmt !== 10)
                    return 'Just now';
                if (seconds < 30 && fmt === 10)
                    return 'EXPIRED';

                if (+new Date(value) === +new Date(0))
                    return '';

                const intervals = {
                    'year': { value: 31536000, abbrev: 'year' },
                    'month': { value: 2592000, abbrev: 'month' },
                    'week': { value: 604800, abbrev: 'week' },
                    'day': { value: 86400, abbrev: 'day' },
                    'hour': { value: 3600, abbrev: 'hr' },
                    'minute': { value: 60, abbrev: 'min' },
                    'second': { value: 1, abbrev: 'sec' }
                };

                let counter;
                let suffix: string;

                for (const i in intervals) {
                    counter = Math.floor(seconds / intervals[i].value);

                    if (counter > 0) {

                        if (fmt === 1) {
                            suffix = i;
                        } else {
                            suffix = intervals[i].abbrev;
                        }

                        let rem = seconds - (counter * intervals[i].value);
                        let counter2 = 0;
                        let suffix2 = '';

                        if (i === 'minute') {
                            counter2 = Math.floor(rem / 60);
                            suffix2 = 'sec';
                        } else if (i === 'hour') {
                            counter2 = Math.floor(rem / 60);
                            suffix2 = 'min';
                        } else if (i === 'day') {
                            counter2 = Math.floor(rem / 3600);
                            suffix2 = 'hr';
                        }

                        if (i === 'month' || i === 'year') {
                            counter = (seconds / intervals[i].value).toFixed(1);
                            counter = counter * 1;
                        }

                        let result = '';

                        if (counter === 1) {
                            result = counter + ' ' + suffix; // singular (1 day)
                        } else {
                            result = counter + ' ' + suffix + 's'; // plural (2 days)
                        }

                        if (counter2 > 0) {
                            if (counter2 === 1) {
                                result += ' ' + counter2 + ' ' + suffix2; // singular (1 day)
                            } else {
                                result += ' ' + counter2 + ' ' + suffix2 + 's'; // plural (2 days)
                            }
                        }

                        return result;
                    }
                }
            }
        }

        return value;
    }
}
