import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private _baseURL = environment.baseUrl;
    private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
    * Save the theme options
    *
    * @param input
    */
     updateTheme(theme: any): Observable<any> {
        let input = { themeOptions: `${theme}` };
        var query = 'mutation updateUser($input: updateUser) { updateUser(input: $input) { id }}';
        var body = JSON.stringify({
            query,
            variables: { input }
        })
        return this._httpClient.post(this._baseURL + '/graphql', body, this._httpOptions);
    }
}
