import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, map, switchMap, take, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { DeviceUpdate, Room, RoomList, SceneUpdate } from './room.types';
import { devStateClass, devStateObj, devView, titleCase, varExists, varObject, varValue, zigbeeStateClass } from 'app/shared/utils';
import { ZigbeeCommand, ZigbeeCommandScene } from '../../devices/device/shared/device.types';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  // Private
  private _deviceUpdates: DeviceUpdate[] = [];
  private _sceneUpdates: SceneUpdate[] = [];
  private _room: BehaviorSubject<Room | null> = new BehaviorSubject(null);
  private _roomTag: BehaviorSubject<RoomList | null> = new BehaviorSubject(null);
  private _rooms: BehaviorSubject<RoomList[] | null> = new BehaviorSubject(null);
  private baseURL = environment.baseUrl;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for room
   */
  get room(): Room {
    return this._room.getValue();
  }

  get room$(): Observable<Room> {
    return this._room.asObservable();
  }

  set room(value: Room) {
    // Store the value
    this._room.next(value);
  }

  /**
   * Setter and getter for rooms
   */
  get rooms$(): Observable<RoomList[]> {
    return this._rooms.asObservable();
  }

  get rooms(): RoomList[] {
    return this._rooms.getValue();
  }

  set rooms(value: RoomList[]) {
    // Store the value
    this._rooms.next(value);
  }

  /**
   * Getter for room tag
   */
  get roomTag$(): Observable<RoomList> {
    return this._roomTag.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  addCommand(cmd: ZigbeeCommand): void {
    this._deviceUpdates.filter(t => !(t.localId === cmd.localId && t.modelId === cmd.modelId && t.roomNumber === cmd.roomNumber));
    this._deviceUpdates.push({
      deviceId: cmd.deviceId,
      localId: cmd.localId,
      modelId: cmd.modelId,
      roomNumber: cmd.roomNumber,
      transactionId: cmd.transactionId,
      command: cmd.strCommand,
      params: cmd.cmdParams,
      statusCode: 'Submitted',
      statusDate: new Date
    });

    // Update device status
    let room = this.room;
    let device = room.devices.find(d => d.id === cmd.deviceId);

    if (device) {
      const index = room.devices.indexOf(device);
      if (index !== -1) {
        room.devices[index] = {
          ...device,
          updateStatus: 'Submitted',
          updateStatusDate: new Date
        };

        // Update observable
        this.room = room;
      }
    }
  }

  addCommandScene(cmd: ZigbeeCommandScene): void {
    this._sceneUpdates.filter(t => !(t.sceneId === cmd.sceneId && t.localId === cmd.localId && t.roomNumber === cmd.roomNumber));
    this._sceneUpdates.push({
      sceneId: cmd.sceneId,
      localId: cmd.localId,
      roomNumber: cmd.roomNumber,
      transactionId: cmd.transactionId,
      command: cmd.strCommand,
      statusCode: 'Submitted',
      statusDate: new Date
    });

    // Update scene status
    let room = this.room;
    let scene = room.scenes.find(s => s.id === cmd.sceneId);

    if (scene) {
      const index = room.scenes.indexOf(scene);
      if (index !== -1) {
        room.scenes[index] = {
          ...scene,
          updateStatus: 'Submitted',
          updateStatusDate: new Date
        };

        // Update observable
        this.room = room;
      }
    }
  }

  /**
   * Get room
   */
  getRoom(roomId: any, filter: any, showHidden: any): Observable<Room> {
    const userScale = localStorage.getItem('temp_scale');

    let devFilter: string = '';
    let offlineOnly: boolean = false;
    const hidden = showHidden ? ' includeHidden: 1 ' : ' includeHidden: 0 ';

    switch (filter) {
      case 'comm': {
        devFilter = `( ${hidden} )`;
        break;
      }
      case 'lighting': {
        devFilter = `( typeIds: 20 ${hidden} )`;
        break;
      }
      case 'services': {
        devFilter = `( ${hidden} )`;
        break;
      }
      case 'shade': {
        devFilter = `( typeIds: 30 ${hidden} )`;
        break;
      }
      case 'thermostat': {
        devFilter = `( typeIds: 10 ${hidden} )`;
        break;
      }
      case 'user-interface': {
        devFilter = `( typeIds: 40 ${hidden} )`;
        break;
      }
      case 'offline': {
        devFilter = `( ${hidden} )`;
        offlineOnly = true;
        break;
      }
      default: {
        devFilter = `( ${hidden} )`;
        break;
      }
    }

    const body = `{ "query": "query { roomDevices ( roomId: ${roomId} )` +
      ` { roomNumber id bridgeId bridgeMac checkedIn { state since } occupied { state since } dnd { state since } hsk { state since } scenes { id localId name state since }` +
      ` devices ` + devFilter + ` { id localId name subunit { id name } hvac ( scale: ${userScale} ) { id temp fanMode setPoint mode }` +
      ` variables { id value boolValue name } model { id name isZigbee identifyText identifyImage icon deviceImage manufacturer { id companyName } type { id displayOrder name }}}}}" }`;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._httpClient.post<Room>(this.baseURL + '/graphql', body, httpOptions)
      .pipe(
        tap((room) => {
          const r = room['data']['roomDevices'];
          const tmp = {
            id: r.id,
            roomNumber: r.roomNumber,
            bridgeId: r.bridgeId,
            bridgeMac: r.bridgeMac,
            checkedIn: r.checkedIn ? r.checkedIn.state : 0,
            checkedInSince: r.checkedIn ? r.checkedIn.since : null,
            occupied: r.occupied ? r.occupied.state : 0,
            occupiedSince: r.occupied ? r.occupied.since : null,
            hsk: r.hsk ? r.hsk.state : 0,
            hskSince: r.hsk ? r.hsk.since : null,
            dnd: r.dnd ? r.dnd.state : 0,
            dndSince: r.dnd ? r.dnd.since : null,
            devices: this.parseDevices(r.devices, offlineOnly),
            scenes: this.parseScenes(r.scenes)
          };

          this._room.next(tmp);
        })
      );
  }

  /**
  * Get room by id
  */
  getRoomById(id: number): Observable<RoomList> {
    return this._rooms.pipe(
      take(1),
      map((rooms) => {

        // Find within the folders and files
        const room = rooms.find(value => value.id === id) || null;

        // Update the room
        this._roomTag.next(room);

        // Return the room
        return room;
      }),
      switchMap((room) => {

        if (!room) {
          return throwError('Could not find the room with id of ' + id + '!');
        }

        return of(room);
      })
    );
  }

  getRoomDevices(roomId: number): Observable<any> {
    const body = `{ "query": "query { roomDevices ( roomId: ${roomId} ) { id devices { id name variables { id name chart steppedLine lineWidth dashStyle color1 color2 color3 } model { id name type { id name }}}}}" }`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._httpClient.post(this.baseURL + '/graphql', body, httpOptions);
  }

  getRooms(): Observable<any> {
    const userScale = localStorage.getItem('temp_scale');
    const body = `{ "query": "query { overview ( scale: ${userScale} ) { floor roomData { roomNumber id tags { id name } checkedIn { state } } }}" }`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._httpClient.post<any>(this.baseURL + '/graphql', body, httpOptions)
      .pipe(
        tap((rooms) => {
          if (rooms['data']['overview']) {
            const tmp = rooms['data']['overview'].map(f => {
              return {
                roomData: f.roomData.map(r => {
                  return { id: r.id, name: r.roomNumber, floor: f.floor, tags: r.tags, checkedIn: r.checkedIn ? (r.checkedIn.state === 1 ? true : false) : false }
                })
              }
            });

            const rms = tmp.map(o => o.roomData).flat()
            this._rooms.next(rms);
          } else {
            this._rooms.next([]);
            console.error('ROOM SERVICE : No Rooms');
          }
        },
          err => console.log("ROOM SERVICE : Error retrieving Rooms")
        )
      );
  }

  onC4Command(data: any) {
    if (data) {
      if (data.device) {

        // Process Device commands
        if (this._deviceUpdates) {
          const roomNumber = data.room;
          const transId = data.device.transactionId;
          const status = data.status;

          let room = this.room;

          if (room.roomNumber !== roomNumber) { return };

          const fnd: any = this._deviceUpdates.filter(t => t.transactionId === transId && t.statusCode === 'Submitted');

          if (!fnd) { return };

          let device = room.devices.find(d => d.id === fnd[0].deviceId);

          if (device) {
            const index = room.devices.indexOf(device);
            if (index !== -1) {
              room.devices[index] = {
                ...device,
                updateStatus: status,
                updateStatusDate: new Date()
              };

              // Update observable
              this.room = room;

              // Remove transaction from device updates
              this._deviceUpdates = this._deviceUpdates.filter(t => t.transactionId === transId && t.statusCode === 'Submitted');
            }
          }
        }
      } else if (data.scene) {

        // Process Scene commands
        if (this._sceneUpdates) {
          const roomNumber = data.scene.roomNumber;
          const transId = data.scene.transactionId;
          const status = data.status;

          let room = this.room;

          if (room.roomNumber !== roomNumber) { return };

          const fnd: any = this._sceneUpdates.filter(t => t.transactionId === transId && t.statusCode === 'Submitted');

          if (!fnd) { return };

          let scene = room.scenes.find(s => s.id === fnd[0].sceneId);

          if (scene) {
            const index = room.scenes.indexOf(scene);
            if (index !== -1) {
              room.scenes[index] = {
                ...scene,
                updateStatus: status,
                updateStatusDate: new Date()
              };

              // Update observable
              this.room = room;

              // Remove transaction from scene updates
              this._sceneUpdates = this._sceneUpdates.filter(t => t.transactionId === transId && t.statusCode === 'Submitted');
            }
          }
        }
      }
    }
  }

  parseDevices(items: any, offlineOnly: boolean) {
    let tmp = [];

    if (items.length > 0) {
      items.map(d => {
        // Initialize values
        const deviceView = devView(d.model.type.id ? d.model.type.id : 0, true);
        const deviceStateObj = devStateObj(deviceView, d.variables, d.hvac);
        const deviceStateClass = devStateClass(deviceStateObj.deviceState);
        const zigbeeObj = varObject(d.variables, 820);
        const macAddr = varValue(d.variables, 821);
        const isOffline = d.model.isZigbee === 1 && zigbeeObj.value.toLowerCase() !== 'online' ? true : false;

        if ((offlineOnly && isOffline) || !offlineOnly) {

          tmp.push({
            id: d.id,
            devImage: d.model.deviceImage ? environment.DEVICE_IMAGE_ENDPOINT + '/' + d.model.deviceImage : 'unknown',
            devState: deviceStateObj.deviceState,
            devStateClass: deviceStateClass,
            devView: deviceView,
            fanMode: d.hvac ? titleCase(d.hvac.fanMode) : null,
            fanMode_chg: d.hvac ? titleCase(d.hvac.fanMode) : null,
            hvacMode: d.hvac ? titleCase(d.hvac.mode) : null,
            hvacMode_chg: d.hvac ? titleCase(d.hvac.mode) : null,
            isDimmer: deviceView === 'lighting' ? varExists(d.variables, 110) : false,
            lightLevel: deviceStateObj.lightLevel,
            lightLevel_chg: deviceStateObj.lightLevel,
            lightState: deviceStateObj.lightState,
            lightState_chg: deviceStateObj.lightState,
            localId: d.localId,
            macAddress: macAddr,
            model: d.model,
            name: d.name,
            setPoint: d.hvac ? d.hvac.setPoint : null,
            setPoint_chg: d.hvac ? d.hvac.setPoint : null,
            subunit: d.subunit,
            updateStatus: '',
            updateStatusDate: null,
            variables: d.variables,
            zigbee: zigbeeObj,
            zigbeeStateClass: d.model.isZigbee === 1 ? zigbeeStateClass(zigbeeObj, macAddr) : 'border-gray-200 dark:border-gray-700'
          });
        }
      });
    }

    return tmp;
  }

  parseScenes(items: any) {
    let tmp = [];

    if (items.length > 0) {
      items.map(s => {
        tmp.push({
          id: s.id,
          localId: s.localId,
          name: s.name,
          sceneState: s.state,
          sceneState_chg: 'NONE',
          since: s.since,
          state: s.state,
          updateStatus: '',
          updateStatusDate: null
        });
      });
    }

    return tmp;
  }

  searchRooms(query: any) {
    return this.rooms.filter(room => room.name.includes(query)).map(o => {
      return { id: o.id, title: 'Room ' + o.name, link: '/rooms/room/' + o.id + '/all' }
    });
  }

  setDeviceProperties(devView: any, updateStatus: any, prop: any, clear: boolean) {
    if (updateStatus !== 'Pending' && updateStatus !== 'Reset') { return }
    let room = this.room;
    let device = room.devices.find(d => d.id === prop.id);

    if (device) {
      const index = room.devices.indexOf(device);
      if (index !== -1) {

        switch (devView) {

          case 'lighting': {
            if (clear) {
              room.devices[index] = {
                ...device,
                updateStatus: '',
                updateStatusDate: null,
                lightLevel_chg: device.lightLevel,
                lightState_chg: device.lightState
              };
            } else {
              room.devices[index] = {
                ...device,
                updateStatus: updateStatus,
                updateStatusDate: new Date(),
                lightLevel_chg: prop.lightLevel,
                lightState_chg: prop.lightState
              };
            }

            // Update observable
            this.room = room;

            // Remove all update transactions for this device
            this._deviceUpdates = this._deviceUpdates.filter(t => t.deviceId === prop.id);
            break;
          }

          case 'thermostat': {
            if (clear) {
              room.devices[index] = {
                ...device,
                updateStatus: '',
                updateStatusDate: null,
                fanMode_chg: device.fanMode,
                hvacMode_chg: device.hvacMode,
                setPoint_chg: device.setPoint
              };
            } else {
              room.devices[index] = {
                ...device,
                updateStatus: updateStatus,
                updateStatusDate: new Date(),
                fanMode_chg: prop.fanMode,
                hvacMode_chg: prop.hvacMode,
                setPoint_chg: prop.setPoint
              };
            }

            // Update observable
            this.room = room;

            // Remove all update transactions for this device
            this._deviceUpdates = this._deviceUpdates.filter(t => t.deviceId === prop.id);
            break;
          }

        }
      }
    }
  }

  setSceneProperties(updateStatus: any, prop: any, clear: boolean) {
    if (updateStatus !== 'Pending' && updateStatus !== 'Reset') { return }
    let room = this.room;
    let scene = room.scenes.find(s => s.id === prop.id);

    if (scene) {
      const index = room.scenes.indexOf(scene);
      if (index !== -1) {

        if (clear) {
          room.scenes[index] = {
            ...scene,
            updateStatus: '',
            updateStatusDate: null,
            sceneState_chg: 'NONE'
          };
        } else if (prop.sceneState === scene.sceneState_chg) {
          room.scenes[index] = {
            ...scene,
            updateStatus: '',
            updateStatusDate: null,
            sceneState_chg: 'NONE'
          };
        } else {
          room.scenes[index] = {
            ...scene,
            updateStatus: updateStatus,
            updateStatusDate: new Date(),
            sceneState_chg: prop.sceneState
          };
        }

        // Update observable
        this.room = room;

        // Remove all update transactions for this scene
        this._sceneUpdates = this._sceneUpdates.filter(t => t.sceneId === prop.id);
      }
    }
  }
}
