import { Observable, Observer } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { io, Socket } from 'socket.io-client';
import { debug } from '../utils';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class SocketioService {
    socket: any;
    observer: Observer<any>;

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
        //       this.setupSocketConnection();
    }

    on(event: string) {
        if (!this.socket) { this.setupSocketConnection() };
        return Observable.create(observer => {
            this.socket.on(event, data => {
                observer.next(data);
            })
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setupSocketConnection() {
        const user = this._userService.user$;
        const connected = this.socket ? this.socket.connected : false;

        if (user && !connected) {
            debug('SOCKETIO SERVICE', ' : connect');
            const token = localStorage.getItem('accessToken');

            this.socket = io(environment.SOCKET_ENDPOINT, {
                auth: {
                    token: token
                }
            });

            //    const conn = socket(host, { upgrade: false, transports: ['websocket'] })

            this.socket.on('connect', () => {
                debug('SOCKETIO SERVICE', `on connect ${this.socket.id}`);
            });

            this.socket.on('error', (error) => {
                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ' : on error >> ' + error);
                this.disconnectSocketConnection();
                const token = localStorage.getItem('accessToken');
                this.socket.auth.token = token;

                setTimeout(() => {
                    debug('SOCKETIO SERVICE', 'connect');
                    this.socket.connect();
                }, 4000);
            });

            this.socket.on('connect_error', err => this.handleError(err, 'connect_error'));
            this.socket.on('connect_failed', err => this.handleError(err, 'connect_failed'));
            this.socket.on('connect_timeout', err => this.handleError(err, 'connect_timeout'));

            this.socket.on('disconnect', (reason) => {
                debug('SOCKETIO SERVICE', `on disconnect >> ${reason}`);
                if (reason !== 'io client disconnect') {
                    debug('SOCKETIO SERVICE', 'connect');
                    const token = localStorage.getItem('accessToken');
                    this.socket.auth.token = token;
                    this.socket.connect();
                }
            });
        }

        /*
                        this.socket.on('reconnect_attempt', () => {
                          console.log('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ' : on reconnect_attempt');
                          this.socket.io.opts.query = {
                            auth_token: token
                          }
                        });
                      */
    }

    disconnectSocketConnection() {
        if (this.socket) {
            debug('SOCKETIO SERVICE', 'disconnect');
            this.socket.disconnect();
        }
    }

    stop(Eventname: string) {
        if (this.socket) {
            debug('SOCKETIO SERVICE', 'off event');
            this.socket.off(Eventname);
        }
    }

    emit(Eventname: string, msg: any) {
        if (this.socket) {
            debug('SOCKETIO SERVICE', 'emit event');
            this.socket.emit(Eventname, msg);
        }
    }

    sendCommand(cmd: any, type: string) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        // Check if connected
        this.setupSocketConnection();

        switch (type) {
            case 'identify':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/control/identify', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `IDENTIFY SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : IDENTIFY FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : IDENTIFY ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'canceljoin':
                return new Promise((resolve, reject) => {
                    this._httpClient.put(environment.SOCKET_ENDPOINT + '/api/v1/control/identify', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `CANCEL JOIN SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : CANCEL JOIN FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : CANCEL JOIN ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'execute':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/control/execute', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `EXECUTE SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : EXECUTE FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : EXECUTE ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'disconnect':
                const httpOptions = {
                    headers: headers,
                    body: JSON.stringify(cmd)
                };

                return new Promise((resolve, reject) => {
                    this._httpClient.delete(environment.SOCKET_ENDPOINT + '/api/v1/control/identify', httpOptions)
                        .subscribe((res) => {
                            // Success
                            if (res) {
                                debug('SOCKETIO SERVICE', `DISCONNECT SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DISCONNECT FAIL >> id ${cmd.localId}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DISCONNECT ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'driverUpdate':
                return new Promise((resolve, reject) => {
                    this._httpClient.patch(environment.SOCKET_ENDPOINT + '/api/v1/drivers', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            debug('SOCKETIO SERVICE', 'UPDATE DRIVER SUCCESS');
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : UPDATE DRIVER ERROR >> ${err.error.message}`);
                            }
                        )
                });

            case 'driverVersion':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/drivers/version', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `DRIVERVERSION SUCCESS >> ${res.statusText}`);
                                resolve(res.body);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DRIVERVERSION FAIL >> status : ${res.status}`);
                                resolve(res.statusText);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DRIVERVERSION ERROR >> ${err.error.message}`);
                            }
                        )
                });

            case 'getDrivers':
                const params = new HttpParams().set("transactionId", cmd.transactionId);
                return new Promise((resolve, reject) => {
                    this._httpClient.get(environment.SOCKET_ENDPOINT + '/api/v1/drivers', { params: params })
                        .subscribe((response: any) => {
                            resolve(response);
                        }, reject);
                });
                break;

            case 'driverUpload':
                let headers2 = new HttpHeaders();
                headers2.append('Content-Type', 'multipart/form-data');
                headers2.append('Accept', 'application/json');

                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/drivers', cmd, { observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `DRIVERUPLOAD SUCCESS >> ${res.statusText}`);
                                resolve(res.body);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DRIVERUPLOAD FAIL >> status : ${res.status}`);
                                resolve(res.statusText);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : DRIVERUPLOAD ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'multiple':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/control/executeMultiple', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `EXECUTE MULTIPLE SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : EXECUTE MULTIPLE FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : EXECUTE MULTIPLE ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;

            case 'properties':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/control/properties', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe(res => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `PROPERTIES SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : PROPERTIES FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        }, reject)
                });
                break;

            case 'propertiesUpdate':
                return new Promise((resolve, reject) => {
                    this._httpClient.put(environment.SOCKET_ENDPOINT + '/api/v1/control/properties', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe(res => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `PROPERTIES UPDATE SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : PROPERTIES UPDATE FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        }, reject)
                });
                break;

            case 'scenes':
                return new Promise((resolve, reject) => {
                    this._httpClient.post(environment.SOCKET_ENDPOINT + '/api/v1/control/scenes', JSON.stringify(cmd), { headers, observe: 'response' })
                        .subscribe((res) => {
                            // Success
                            if (res.status === 200) {
                                debug('SOCKETIO SERVICE', `SCENES SUCCESS >> id ${cmd.localId}`);
                            } else {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : SCENES FAIL >> id ${cmd.localId} - status : ${res.status}`);
                            }
                        },
                            err => {
                                console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ` : SCENES ERROR >> ${err.error.message}`);
                            }
                        )
                });
                break;
        }

    }

    getSocketioState() {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._httpClient.get(environment.SOCKET_ENDPOINT + '/api/v1/sockets', httpOptions);
    }

    private handleError(error, action: any) {
        console.error('SOCKETIO SERVICE ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ' : ERROR ' + action + ' >> ' + error);
        //    if (error.error instanceof Error) {
        //      let errMessage = error.error.message;
        //      return Observable.throw(errMessage);
        //    }
        //    return Observable.throw(error || 'Socket.io server error');
    }
}