import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/dashboards/site-overview'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards/site-overview' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'azure/redirect', loadChildren: () => import('app/modules/auth/azure-redirect/azure-redirect.module').then(m => m.AzureRedirectModule) },
            { path: 'google/redirect', loadChildren: () => import('app/modules/auth/google-redirect/google-redirect.module').then(m => m.GoogleRedirectModule) },
            { path: 'okta/redirect', loadChildren: () => import('app/modules/auth/okta-redirect/okta-redirect.module').then(m => m.OktaRedirectModule) }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out/:scope', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            // Dashboards
            {
                path: 'dashboards', children: [
                    { path: 'alert', loadChildren: () => import('app/modules/admin/dashboards/alert/alert.routes') },
                    { path: 'issues', loadChildren: () => import('app/modules/admin/dashboards/current-issue/current-issue.module').then(m => m.CurrentIssueModule) },
                    { path: 'hvac', loadChildren: () => import('app/modules/admin/dashboards/hvac/hvac.routes') },
                    { path: 'privacy-service', loadChildren: () => import('app/modules/admin/dashboards/privacy-service/privacy-service.module').then(m => m.PrivacyServiceModule) },
                    { path: 'room-overview', loadChildren: () => import('app/modules/admin/dashboards/room-overview/room-overview.module').then(m => m.RoomOverviewModule) },
                    { path: 'room-overview-vs', loadChildren: () => import('app/modules/admin/dashboards/room-overview-vs/room-overview-vs.module').then(m => m.RoomOverviewVSModule) },
                    { path: 'room-timeline', loadChildren: () => import('app/modules/admin/dashboards/room-timeline/room-timeline.module').then(m => m.RoomTimelineModule) },
                    { path: 'service-alert', loadChildren: () => import('app/modules/admin/dashboards/service-alert/service-alert.module').then(m => m.ServiceAlertModule) },
                    { path: 'site-overview', loadChildren: () => import('app/modules/admin/dashboards/site-overview/site-overview.module').then(m => m.SiteOverviewModule) },
                    { path: 'temperature', loadChildren: () => import('app/modules/admin/dashboards/temperature/temperature.module').then(m => m.TemperatureModule) }
                ]
            },

            // Devices
            {
                path: 'devices', children: [
                    { path: 'device-state', loadChildren: () => import('app/modules/admin/devices/device-state/device-state.module').then(m => m.DeviceStateModule) },
                    { path: 'offline', loadChildren: () => import('app/modules/admin/devices/device-offline/device-offline.module').then(m => m.DeviceOfflineModule) },
                    { path: 'unidentified', loadChildren: () => import('app/modules/admin/devices/device-unidentified/device-unidentified.module').then(m => m.DeviceUnidentifiedModule) },
                    { path: 'room/:roomId/shade/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/blind/blind.module').then(m => m.BlindModule) },
                    { path: 'room/:roomId/director/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/director/director.module').then(m => m.DirectorModule) },
                    { path: 'room/:roomId/thermostat/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/thermostat/thermostat.module').then(m => m.ThermostatModule) },
                    { path: 'room/:roomId/lighting/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/lighting/lighting.module').then(m => m.LightingModule) },
                    { path: 'room/:roomId/other/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/other-device/other-device.module').then(m => m.OtherDeviceModule) },
                    { path: 'room/:roomId/user-interface/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/user-interface/user-interface.module').then(m => m.UserInterfaceModule) },
                    { path: 'room/:roomId/relay-contact/:deviceId', loadChildren: () => import('app/modules/admin/devices/device/view/relay-contact/relay-contact.module').then(m => m.RelayContactModule) }
                ]
            },

            // Drivers
            {
                path: 'drivers', children: [
                    { path: 'driver-manage', loadChildren: () => import('app/modules/admin/drivers/driver-manage/driver-manage.module').then(m => m.DriverManageModule) },
                    { path: 'driver-update', loadChildren: () => import('app/modules/admin/drivers/driver-update/driver-update.module').then(m => m.DriverUpdateModule) },
                    { path: 'driver-version', loadChildren: () => import('app/modules/admin/drivers/driver-version/driver-version.module').then(m => m.DriverVersionModule) }
                ]
            },

            // Rooms
            {
                path: 'rooms', children: [
                    { path: 'room', loadChildren: () => import('app/modules/admin/rooms/room/room.module').then(m => m.RoomModule) }
                ]
            },

            // Administration
            {
                path: 'admin', children: [
                    { path: 'users', loadChildren: () => import('app/modules/admin/apps/users/users.module').then(m => m.UsersModule) },
                    { path: 'action-log', loadChildren: () => import('app/modules/admin/apps/action-log/action-log.module').then(m => m.ActionLogModule) },
                    { path: 'room-tags', loadChildren: () => import('app/modules/admin/apps/room-tags/room-tags.module').then(m => m.RoomTagsModule) },
                    { path: 'sockets', loadChildren: () => import('app/modules/admin/apps/socket-state/socket-state.module').then(m => m.SocketStateModule) },
                    { path: 'sites', loadChildren: () => import('app/modules/admin/apps/sites/sites.module').then(m => m.SitesModule) },
                    { path: 'interfaces', loadChildren: () => import('app/modules/admin/apps/interfaces/interfaces.module').then(m => m.InterfacesModule) },
                ]
            },

            // Help Centre
            {
                path: 'help-center', children: [
                    { path: 'support', loadChildren: () => import('app/modules/admin/help-center/support/support.module').then(m => m.SupportModule) },
                ]
            },
            // Apps
            {
                path: 'apps', children: [
                    { path: 'help-center', loadChildren: () => import('app/modules/admin/apps/help-center/help-center.module').then(m => m.HelpCenterModule) },
                    { path: 'users', loadChildren: () => import('app/modules/admin/apps/users/users.module').then(m => m.UsersModule) },
                ]
            },

            // Pages
            {
                path: 'pages', children: [

                    // Error
                    {
                        path: 'error', children: [
                            { path: '400', loadChildren: () => import('app/modules/admin/pages/error/error-400/error-400.module').then(m => m.Error400Module) },
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                            { path: '413', loadChildren: () => import('app/modules/admin/pages/error/error-413/error-413.module').then(m => m.Error413Module) },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module) },
                            { path: 'license', loadChildren: () => import('app/modules/admin/pages/error/error-license/error-license.module').then(m => m.ErrorLicenseModule) }
                        ]
                    },

                    // Loading
                    { path: 'page-loading', loadChildren: () => import('app/modules/admin/pages/page-loading/page-loading.module').then(m => m.PageLoadingModule) },

                    // Settings
                    { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.routes') },
                ]
            },

            // Documentation
            {
                path: 'docs', children: [

                    // Changelog
                    { path: 'changelog', loadChildren: () => import('app/modules/admin/docs/changelog/changelog.routes') },

                    // Guides
                    { path: 'guides', loadChildren: () => import('app/modules/admin/docs/guides/guides.module').then(m => m.GuidesModule) }
                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
