<div class="w-full max-w-3xl">

    <!-- Form -->
    <form [formGroup]="accountForm">

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Profile</div>
        </div>

        <div class="flex flex-col mt-4 pt-6 border-t space-y-8">

            <!-- Name -->
            <div class="flex sm:items-center">
                <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
                <div class="ml-6 leading-6">{{this.user.firstname}} {{this.user.lastname}}</div>
            </div>

            <!-- Email -->
            <div class="flex sm:items-center">
                <mat-icon [svgIcon]="'heroicons_outline:envelope'"></mat-icon>
                <div class="ml-6 leading-6">{{this.user.email}}</div>
            </div>

            <!-- Company -->
            <div class="flex sm:items-center">
                <mat-icon [svgIcon]="'iconsmind:hotel'"></mat-icon>
                <div class="ml-6 leading-6">{{this.company}}</div>
            </div>

        </div>

        <!-- Divider -->
        <div class="my-10 border-t"></div>

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Preferences</div>
            <div class="text-secondary">Manage your own preferences.</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <!-- Temperature Scale -->
            <div class="sm:col-span-2">
                <mat-form-field class="fw-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Temperature Scale</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:thermostat'" matPrefix></mat-icon>
                    <mat-select formControlName="tempScale" required>
                        <mat-option value="C">Celsius</mat-option>
                        <mat-option value="F">Fahrenheit</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Stay Logged In -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Stay Logged In</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:key'" matPrefix></mat-icon>
                    <mat-select formControlName="stayLoggedIn" required>
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Page Refresh Rate -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                    <mat-label>Page Refresh Rate (seconds)</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:timer'" matPrefix></mat-icon>
                    <input formControlName="refreshRate" matInput type="number" [autocomplete]="'off'" required>
                    <mat-error *ngIf="accountForm.get('refreshRate').hasError('required')">
                        Required
                    </mat-error>
                    <mat-error *ngIf="!accountForm.get('refreshRate').hasError('required') &&
                        accountForm.get('refreshRate').hasError('rateError')">
                        Rate must be 0 or a minimum of 5 seconds
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Home Page -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Home Page</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:home'" matPrefix></mat-icon>
                    <mat-select formControlName="homePage" required>
                        <mat-option *ngFor="let menu of menuItems" [value]="+menu.id">
                            {{menu.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Divider -->
        <div class="mt-8 mb-8 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
            <div class="flex items-center mr-4" *ngIf="flashMessage">
                <ng-container *ngIf="flashMessage === 'success'">
                    <mat-icon class="text-green-500" [svgIcon]="'heroicons_outline:check'"></mat-icon>
                    <span class="ml-2">Account updated</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon class="text-red-500" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    <span class="ml-2">An error occurred, try again!</span>
                </ng-container>
            </div>
            <button mat-stroked-button type="button">
                Cancel
            </button>
            <button class="ml-4" mat-flat-button type="button" [color]="'primary'"
                [disabled]="accountForm.invalid || accountForm.pristine" (click)="updateAccount()">
                Save
            </button>
        </div>
    </form>

</div>