// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
// import { Observable, of, switchMap } from 'rxjs';
// import { AuthService } from 'app/core/auth/auth.service';

// @Injectable({
//     providedIn: 'root'
// })
// export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad
// {
//     /**
//      * Constructor
//      */
//     constructor(
//         private _authService: AuthService,
//         private _router: Router
//     )
//     {
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Public methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Can activate
//      *
//      * @param route
//      * @param state
//      */
//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
//     {
//         return this._check();
//     }

//     /**
//      * Can activate child
//      *
//      * @param childRoute
//      * @param state
//      */
//     canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
//     {
//         return this._check();
//     }

//     /**
//      * Can load
//      *
//      * @param route
//      * @param segments
//      */
//     canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
//     {
//         return this._check();
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Private methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Check the authenticated status
//      *
//      * @private
//      */
//     private _check(): Observable<boolean>
//     {
//         // Check the authentication status
//         return this._authService.check()
//                    .pipe(
//                        switchMap((authenticated) => {

//                            // If the user is authenticated...
//                            if ( authenticated )
//                            {
//                                // Redirect to the root
//                                const homePage = localStorage.getItem('home_page_url');
//                                const redirectURL = homePage === "" ? '/dashboards/site-overview' : homePage;
//                                this._router.navigate([redirectURL]);

//                                // Prevent the access
//                                return of(false);
//                            }

//                            // Allow the access
//                            return of(true);
//                        })
//                    );
//     }
// }

import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) => {
            // If the user is authenticated...
            if (authenticated) {
                // Redirect to the root
                // const homePage = localStorage.getItem('home_page_url');
                // const redirectURL = homePage === "" ? '/dashboards/site-overview' : homePage;
                // router.navigate([redirectURL]);

                return of(router.parseUrl(''));
            }

            // Allow the access
            return of(true);
        }),
    );
};
