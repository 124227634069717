<!-- Button -->
<div class="flex flex-row items-center">
    <button mat-icon-button [matMenuTriggerFor]="sites">
        <mat-icon [ngClass]="{'text-red-500': user.site.name === ''}" [svgIcon]="'iconsmind:hotel'">
        </mat-icon>
    </button>
    <div>{{user.site.name}}</div>
</div>

<!-- Site menu -->
<mat-menu [xPosition]="'before'" #sites="matMenu" class="max-h-120">
    <ng-container *ngFor="let site of availableSites; trackBy: trackByFn">
        <button mat-menu-item (click)="updateUserSite(site.id)">
            <span class="flex items-center">
                <span class="ml-3">{{site.name}}</span>
                <span class="text-primary-400 ml-3">({{site.id}})</span>
            </span>
        </button>
    </ng-container>
</mat-menu>