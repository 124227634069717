import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError, catchError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const _authService = inject(AuthService);
    const _router = inject(Router);

    // Clone the request object
    let newReq = req.clone();

    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (!newReq.url.startsWith('./assets/') && !newReq.url.startsWith('assets/icons/') && !newReq.url.endsWith('/redirect')) {
        if (_authService.accessToken && !AuthUtils.isTokenExpired(_authService.accessToken)) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + _authService.accessToken)
            });
        }
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {

            let handled: boolean = false;

            if (error instanceof HttpErrorResponse) {

                if (error.error instanceof ErrorEvent) {
                    console.error(`AUTH INTERCEPTOR : Error Event >> ${error}`);
                } else {
                    console.error(`AUTH INTERCEPTOR : Error Status >> ${error.status} ${error.statusText}`);
                    let strBody: string = '';

                    switch (error.status) {
                        case 0:
                            if (req.url.endsWith('/user/login')) {
                                handled = false;
                                break;
                            }
                            break;

                        case 400:   // bad request
                            const msg = error.error.errors ? (error.error.errors[0].message ? error.error.errors[0].message : 'none') : 'none';
                            strBody = (typeof req.body === 'string') ? req.body : '';

                            if (strBody.includes('mutation resetPassword')) {
                                handled = false
                            } else if (msg.includes('license expired')) {
                                _router.navigate(['pages/error/license'])
                                handled = true
                            } else if (req.url.endsWith('/user/login')) {
                                handled = false;
                                break;
                            } else {
                                _router.navigate(['pages/error/400'])
                                handled = true
                            }
                            break;

                        case 401:   // unauthorized
                            strBody = (typeof req.body === 'string') ? req.body : '';

                            if (_authService.isAuthenticated === false) {
                                handled = false
                            } else if (strBody.includes('mutation resetPassword')) {
                                handled = false
                            } else {
                                // Sign out
                                //                             if (!req.url.endsWith('/user/login')) {
                                _authService.signOut2();
                                //                           }

                                // Reload the app
                                location.reload();
                            }
                            break;

                        case 403:   // forbidden
                            if (_authService.isAuthenticated === false) {
                                handled = false
                            } else if (req.url.endsWith('/user/login')) {
                                handled = false
                            } else {
                                _authService.signOut2();

                                // Reload the app
                                location.reload();
                            }
                            break;

                        case 404:   // not found
                            handled = false
                            break;

                        case 413:   // request entity too large
                            _router.navigate(['pages/error/413'])
                            handled = true
                            break;

                        case 500:   // internal server error
                            if (_authService.isAuthenticated === false) {
                                handled = false
                            } else if (req.url.endsWith('/user/login')) {
                                handled = false;
                                break;
                            } else if (req.url.endsWith('/user/token')) {
                                handled = false;
                                break;
                            } else if (req.url.endsWith('/refresh')) {
                                handled = false;
                                break;
                            } else {
                                _router.navigate(['pages/error/500'])
                                handled = true
                                break;
                            }

                        case 502:   // Bad Gateway
                            break;

                        case 503:   // Service unavailable error
                            break;

                        default:
                            // Sign out
                            _authService.signOut2();

                            // Reload the app
                            location.reload();
                            break;
                    }
                }
            } else {
                // Client Error
                console.error(`AUTH INTERCEPTOR : Client Error >> ${error}`);
            }

            if (handled) {
                return of(error);
            } else {
                return throwError(error);
            }
        })
    );
}
