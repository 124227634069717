// Air Conditioning Temperature group
export function acTempGroup(value: any, scale: any) {
    const value2 = scale === 'C' ? value : (value - 32) * 5 / 9;
    if (!value2 || value2 === 0) {
        return 'notemp';
    } else if (value2 < 18) {
        return 'cold';
    } else if (value2 < 21) {
        return 'cool';
    } else if (value2 < 24) {
        return 'ideal';
    } else if (value2 < 28) {
        return 'warm';
    } else {
        return 'hot';
    }
}

export function arraysEqual(a, b) {
    if (a.length !== b.length)
        return false;
    for (var i = a.length; i--;) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}

// Device Chart bar color translation
export function barColor(value: any) {
    const offColor = 'rgb(150,158,180)';

    if (!value) {
        return 'black';
    } else if (value === '0') {
        return offColor;
    } else {

        switch (value.toUpperCase()) {
            case 'OFF': case 'CLOSED': case 'OFFLINE':
                return offColor;
            case 'OPENED': case 'ON': case '1': case 'ECO': case "ONLINE":
                return 'rgb(59,152,80)';
            case 'MEDIUM': case 'MED':
                return 'yellow';
            case 'HIGH': case 'HEAT':
                return 'rgb(244,91,91)';
            case 'AUTO':
                return 'rgb(103,58,183)';
            case 'COOL': case 'LOW':
                return 'rgb(66,135,251)';
            case 'N/A':
                return 'rgb(255,201,14)'
            default:
                return 'rgb(0, 66, 132)';
        }
    }
}

// Show message if debug true
export function debug(source: string, message: string) {
    let debug = localStorage.getItem('debug');
    if (debug && debug === 'log') {
        var moment = require('moment/moment');
        console.log(source + ' ' + moment(new Date()).format('YYYY-MM-DD hh:mm:ss') + ' : ' + JSON.stringify(message));
    }
}

export function devStateObj(devView: string, variables: any, hvac: any) {
    // Set default values
    let lightState = 0;
    let lightLevel = 0;
    let shadeState = 0;
    let relayState = 0;
    let dndState = 0;
    let hskState = 0;
    let trayState = 0;
    let deviceState = '';

    switch (devView) {
        case 'lighting':
            lightState = varBoolValue(variables, 100);
            lightLevel = varValue(variables, 110, true);
            deviceState = lightState === 1 ? 'light-on' : '';
            break;
        case 'shade':
            shadeState = varBoolValue(variables, 400);
            deviceState = shadeState === 0 ? '' : 'shade-up';
            break;
        case 'user-interface':
            dndState = varBoolValue(variables, 300);
            hskState = varBoolValue(variables, 301);
            trayState = varBoolValue(variables, 302);
            if (dndState === 1) {
                deviceState = 'dnd';
            } else if (hskState === 1) {
                deviceState = 'hsk';
            } else if (trayState === 1) {
                deviceState = 'tray';
            }
            break;
        case 'thermostat':
            if (hvac) {
                deviceState = hvac.mode ? 'hvac-' + hvac.mode.toLowerCase() : 'none';
            }
            break;
        case 'relay-contact':
            relayState = varBoolValue(variables, 667);
            deviceState = relayState === 0 ? 'relay-open' : '';
            break;
    }
    return { lightState: lightState, lightLevel: lightLevel, shadeState: shadeState, dndState: dndState, hskState: hskState, trayState: trayState, deviceState: deviceState, relayState: relayState };
}

// Device Icon Color
export function devStateClass(devState: string) {
    switch (devState) {
        case 'light-on':
            return 'text-yellow-400';
        case 'shade-up': case 'hsk':
            return 'text-green-400';
        case 'dnd': case 'hvac-heat':
            return 'text-red-400 dark:text-red-500';
        case 'tray': case 'hvac-cool':
            return 'text-blue-400';
        case 'hvac-auto':
            return 'text-purple-400';
        case 'relay-open':
            return 'text-green-400';
        default:
            return 'text-hint';
    }
}

// Device View
export function devView(devTypeId: number, uiAlternate: boolean = false) {
    switch (devTypeId) {
        case 10:
            return 'thermostat';
        case 20:
            return 'lighting';
        case 30:
            return 'shade';
        case 40:
            return uiAlternate ? 'user-interface' : 'other';
        case 44:
            return 'user-interface';
        case 66:
            return 'relay-contact';
        case 94:
            return 'director';
        case 95:
            return 'director';
        default:
            return 'other';
    }
}

// Duration
export function duration(startTime: any, endTime: any) {
    var moment = require('moment/moment');
    const duration = moment.duration(endTime.diff(startTime));
    const days = parseInt(duration.asDays());
    const hrs = parseInt(duration.asHours());
    const mins = parseInt(duration.asMinutes()) % 60;
    const secs = parseInt(duration.asSeconds()) % 60;

    return days > 0 ? (days + (days > 1 ? ' days' : 'day')) :
        (hrs > 0 ? hrs + (hrs > 1 ? ' hrs ' : ' hr ') : '') +
        (mins > 0 ? mins + (mins > 1 ? ' mins ' : ' min ') : '') +
        (secs > 0 && hrs < 1 ? secs + (secs > 1 ? ' secs ' : ' sec ') : '');
}

// return date depending on supplied Start and Stop date ranges
export function getNewDate(code: string, dte: any, dte2: any) {
    const dteNew = new Date(dte);
    const dteComp = new Date(dte2);

    if (code === 'START' && dteNew < dteComp) {
        return dteComp;
    } else if (code === 'STOP' && dteNew > dteComp) {
        return dteComp;
    } else {
        return dteNew;
    }
}

export function getParmDateRanges(startDate: any, startTime: any, rng: any, isLocal: any, tzSite: any) {
    var moment = require('moment/moment');
    const strDate = moment(startDate).format('YYYY-MM-DD');

    let startTime2 = moment(strDate + ' ' + startTime);
    let stopTime = moment(new Date());
    let stepSizeX = +rng;

    switch (rng) {
        case 'today':
            stepSizeX = 24;
            break;

        case 'yesterday':
            stopTime = moment(strDate + ' 23:59:59');
            stepSizeX = 24;
            break;

        default:
            stopTime = moment(startTime2).add(+rng, 'hours');
            stepSizeX = +rng;
    }

    if (moment(stopTime) > moment(new Date)) {
        stopTime = moment(new Date);
    }

    const minDate = moment(new Date).subtract(1, 'hours');
    if (startTime > minDate) {
        startTime = minDate;
    }

    const parmStartTime = moment(startTime2, 'YYYY-MM-DD HH:mm', true).format();
    const parmStopTime = moment(stopTime, 'YYYY-MM-DD HH:mm', true).format();
    const parmStartTimeUTC = isLocal ? moment.utc(parmStartTime).toISOString() : moment.tz(parmStartTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();
    const parmStopTimeUTC = isLocal ? moment.utc(parmStopTime).toISOString() : moment.tz(parmStopTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();

    return {
        stepSizeX: stepSizeX,
        parmStartTime: parmStartTime,
        parmStopTime: parmStopTime,
        parmStartTimeUTC: parmStartTimeUTC,
        parmStopTimeUTC: parmStopTimeUTC,
        tzOffset: isLocal ? moment(parmStartTimeUTC).utcOffset() : moment.tz(parmStartTime, 'YYYY-MM-DD HH:mm', tzSite).utcOffset()
    }
}

export function getParmDateRanges2(startDate: any, startTime: any, stopDate: any, stopTime: any, allDay: boolean, isLocal: any, tzSite: any) {
    var moment = require('moment/moment');
    const startDate_s = moment(startDate).format('YYYY-MM-DD');
    const stopDate_s = moment(stopDate).format('YYYY-MM-DD');

    let dateFrom = null;
    let dateTo = null;

    if (allDay === true) {
        dateFrom = moment(startDate_s + ' 00:00:00');
        dateTo = moment(stopDate_s + ' 23:59:59');
    } else {
        dateFrom = moment(startDate_s + ' ' + startTime);
        dateTo = moment(stopDate_s + ' ' + stopTime);
    }

    if (dateFrom > moment(new Date)) {
        dateFrom = moment(new Date);
    }

    if (dateTo > moment(new Date)) {
        dateTo = moment(new Date);
    }

    const parmStartTime = moment(dateFrom, 'YYYY-MM-DD HH:mm', true).format();
    const parmStopTime = moment(dateTo, 'YYYY-MM-DD HH:mm', true).format();
    const parmStartTimeUTC = isLocal ? moment.utc(parmStartTime).toISOString() : moment.tz(parmStartTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();
    const parmStopTimeUTC = isLocal ? moment.utc(parmStopTime).toISOString() : moment.tz(parmStopTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();

    return {
        parmStartTime: parmStartTime,
        parmStopTime: parmStopTime,
        parmStartTimeUTC: parmStartTimeUTC,
        parmStopTimeUTC: parmStopTimeUTC,
        tzOffset: isLocal ? moment(parmStartTimeUTC).utcOffset() : moment.tz(startTime, 'YYYY-MM-DD HH:mm', tzSite).utcOffset()
    }
}

export function getParmDateRanges3(startDate: any, startTime: any, stopDate: any, stopTime: any, allDay: boolean, isLocal: any, tzSite: any) {
    var moment = require('moment/moment');
    const startDate_s = moment(startDate).format('YYYY-MM-DD');
    const stopDate_s = moment(stopDate).format('YYYY-MM-DD');

    let dateFrom = null;
    let dateTo = null;

    if (allDay === true) {
        dateFrom = moment(startDate_s + ' 00:00:00');
        dateTo = moment(stopDate_s + ' 23:59:59');
    } else {
        dateFrom = moment(startDate_s + ' ' + startTime);
        dateTo = moment(stopDate_s + ' ' + stopTime);
    }

    const parmStartTime = moment(dateFrom, 'YYYY-MM-DD HH:mm', true).format();
    const parmStopTime = moment(dateTo, 'YYYY-MM-DD HH:mm', true).format();
    const parmStartTimeUTC = isLocal ? moment.utc(parmStartTime).toISOString() : moment.tz(parmStartTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();
    const parmStopTimeUTC = isLocal ? moment.utc(parmStopTime).toISOString() : moment.tz(parmStopTime, 'YYYY-MM-DD HH:mm', tzSite).toISOString();

    return {
        parmStartTime: parmStartTime,
        parmStopTime: parmStopTime,
        parmStartTimeUTC: parmStartTimeUTC,
        parmStopTimeUTC: parmStopTimeUTC,
        tzOffset: isLocal ? moment(parmStartTimeUTC).utcOffset() : moment.tz(startTime, 'YYYY-MM-DD HH:mm', tzSite).utcOffset()
    }
}

export function getUTCOffset(tz: any) {
    var moment = require('moment/moment');
    const offset = moment.tz(moment(), tz).utcOffset();
    const hours = Math.floor(offset / 60).toString();
    const minutes = Math.floor(offset % 60).toString();
    const sign = offset < 0 ? '' : '+';
    return 'UTC ' + sign + hours + ':' + minutes.padStart(2, "0");
}

export function servState(dnd: any, hsk: any, tray: any) {
    const dndState = dnd ? dnd.state : 0;
    const hskState = hsk ? hsk.state : 0;
    const trayState = tray ? tray.detected : 0;

    if (dndState === 1) {
        return { id: dnd.id, state: 'dnd', since: dnd.since, icon: 'do_not_disturb' };
    } else if (hskState === 1) {
        return { id: hsk.id, state: 'hsk', since: hsk.since, icon: 'cleaning_services' };
    } else if (trayState === 1) {
        return { id: 0, state: 'tray', since: tray.since, icon: 'room_service' };
    }

    return { id: 0, state: 'none', since: null, icon: 'none' };
}

export function sortData(sortField: any, order: any, data: any) {
    const arr = sortField.split('~');
    const fldType = arr[1];
    const arr2 = arr[0].split('.');
    const fldName = arr2.length > 1 ? arr2[0] : arr[0];
    const fldName2 = arr2.length > 1 ? arr2[1] : '';

    switch (fldType) {
        case 's':
            data.sort((a, b) => {
                const fieldA = fldName2 === '' ? (a[fldName] ? a[fldName].toString().toUpperCase() : '') : (a[fldName][fldName2] ? a[fldName][fldName2].toString().toUpperCase() : '');
                const fieldB = fldName2 === '' ? (b[fldName] ? b[fldName].toString().toUpperCase() : '') : (b[fldName][fldName2] ? b[fldName][fldName2].toString().toUpperCase() : '');
                return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
            });
            break;
        case 'n':
            data.sort((a, b) => {
                const fieldA = fldName2 === '' ? (a[fldName] ? +a[fldName] : 0) : (a[fldName][fldName2] ? +a[fldName][fldName2] : 0);
                const fieldB = fldName2 === '' ? (b[fldName] ? +b[fldName] : 0) : (b[fldName][fldName2] ? +b[fldName][fldName2] : 0);
                return order === 'asc' ? fieldA - fieldB : fieldB - fieldA;
            });
            break;
        case 'd':
            data.sort((a, b) => {
                if (fldName2 === '') {
                    return order === 'asc' ? a[fldName] > b[fldName] ? 1 : -1 : a[fldName] < b[fldName] ? 1 : -1;
                } else {
                    return order === 'asc' ? a[fldName][fldName2] > b[fldName][fldName2] ? 1 : -1 : a[fldName][fldName2] < b[fldName][fldName2] ? 1 : -1;
                }
            });
            break;
    }
}

export function titleCase(val: string) {
    if (val) {
        return val[0].toUpperCase() + val.slice(1).toLowerCase();
    } else {
        return null;
    }
}

// Variable Boolean Value
export function varBoolValue(items: any, id: number) {
    if (items) {
        const varValue = items.filter(variable => variable.id === id).map(rec => rec.boolValue);
        return varValue ? varValue[0] : 0;
    } else {
        return 0;
    }
}

// Variable Exists
export function varExists(items: any, id: number) {
    if (items) {
        const varValue = items.filter(variable => variable.id === id).map(rec => rec.boolValue);
        return varValue ? varValue.length === 0 ? false : true : false;
    } else {
        return false;
    }
}

// Variable Object
export function varObject(items: any, id: number) {
    if (items) {
        const rec = items.filter(variable => variable.id === id);
        return rec.length > 0 ? { value: rec[0].value, since: rec[0].since } : { value: '', since: null };
    } else {
        return { value: '', since: null };
    }
}

// Variable Value
export function varValue(items: any, id: number, returnNumeric: boolean = false) {
    if (items) {
        const varValue = items.filter(variable => variable.id === id).map(rec => rec.value);
        return varValue.length > 0 ? (returnNumeric ? +varValue[0] : varValue[0]) : (returnNumeric ? 0 : '');
    } else {
        return returnNumeric ? 0 : '';
    }
}

// Variable Value2 include date
export function varValue2(items: any, id: number) {
    if (items) {
        const varValue = items.filter(variable => variable.id === id).map(r => {
            return { value: r.value ? r.value : '', since: r.since }
        });
        return varValue.length > 0 ? varValue[0] : { value: '', since: null };
    } else {
        return { value: '', since: null }
    }
}

// Zigbee State Color
export function zigbeeStateClass(zigbee: any, macAddr: any) {
    if (macAddr === 'N/A') {
        return 'border-red-400 dark:border-red-600';
    } else if (zigbee.value.toLowerCase() === 'offline') {
        return 'border-blue-400 dark:border-blue-600';
    } else if (zigbee.value.toLowerCase() === 'online') {
        return 'border-green-400 dark:border-green-400'
    } else if (zigbee.value === '') {
        return 'border-amber-400 dark:border-amber-600'
    } else {
        return '';
    }
}

export const removeEmpty = (obj:any) => {
    Object.entries(obj).forEach(([key, val])  =>
        (val && typeof val === 'object') && removeEmpty(val) ||
        (val === null || val === "") && delete obj[key]
    );
    return obj;
};
