import { NgModule } from '@angular/core';
import { ElapsedTimePipe } from '@fuse/pipes/elapsed-time/elapsed-time.pipe';

@NgModule({
    declarations: [
        ElapsedTimePipe
    ],
    exports     : [
        ElapsedTimePipe
    ]
})
export class ElapsedTimePipeModule
{
}
