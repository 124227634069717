import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector       : 'settings-security',
    templateUrl    : './security.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatButtonModule],
})
export class SettingsSecurityComponent implements OnInit
{
    @ViewChild('securityNgForm') securityNgForm: NgForm;

    flashMessage: 'success' | 'error' | null = null;
    securityForm: UntypedFormGroup;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.securityForm = this._formBuilder.group({
            oldPassword: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
            twoStep: [true],
            askPasswordChange: [false]
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    cancel(): void {
        // Reset the form
        this.securityNgForm.resetForm();
    }

    changePassword(): void {
        // Get the security object
        let input = this.securityForm.getRawValue();

        // Remove NOT SUPPORTED
        delete input.twoStep;
        delete input.askPasswordChange;

        // Disable the form
        this.securityForm.disable();

        // Update the user password
        this._authService.changePassword(input)
            .subscribe(
                (response) => {

                    // Re-enable the form
                    this.securityForm.enable();

                    // Reset the form
                    this.securityNgForm.resetForm();

                    if (response.errors) {

                        // Show error
                        this.showFlashMessage('error');

                    } else {

                        // Show a success message
                        this.showFlashMessage('success');
                    }
                },
                (response) => {

                    // Re-enable the form
                    this.securityForm.enable();

                    // Reset the form
                    this.securityNgForm.resetForm();

                    // Show error
                    this.showFlashMessage('error');

                }
            );
    }

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {

            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 4000);
    }
}

/**
* Confirm password validator
*
* @param {AbstractControl} control
* @returns {ValidationErrors | null}
*/
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const confirmPassword = control.parent.get('confirmPassword');

    if (!password || !confirmPassword) {
        return null;
    }

    if (confirmPassword.value === '') {
        return null;
    }

    if (password.value === confirmPassword.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
